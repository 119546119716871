import { get, post, queryParams } from "./commons";
import * as axios from "./commons";
const SEARCH_API = '/rest/public/searchprojects';
const FILTERS_API = '/rest/filters';
const WOCAT_API_ROOT = '/api/amp-wocat';
const WOCAT_SAHEL_COUNTRIES_API = `${WOCAT_API_ROOT}/countries/sahel`;
const WOCAT_PROJECTS_API = `${WOCAT_API_ROOT}/projects`;
const WOCAT_TYPES_API = `${WOCAT_API_ROOT}/types`;
const WOCAT_SEARCH_API = `${WOCAT_API_ROOT}/search`;
const WOCAT_INSTITUTIONS_API = `${WOCAT_API_ROOT}/institutions`;
const WOCAT_LANGUAGES_API = `${WOCAT_API_ROOT}/languages`;
const AMP_SETTINGS_API = `/rest/amp/settings`;
const INDICATORS_API = '/rest/dashboard/me/indicatorReportsByProgramCountry';
const COUNT_API = '/rest/public/projectCount';
const TOTAL_API = '/rest/public/totalByMeasure';
const TOP_API = '/rest/dashboard/tops/';
const VIZ_API_ROOT = process.env.REACT_APP_API_ROOT;
export function _loadAMpSettings(api_root) {
  return get(api_root + AMP_SETTINGS_API);
}
const filters_data = {
  "filters": {
    "date": {
      "start": "2000-01-01",
      "end": "2030-12-31"
    }
  },
  "include-location-children": true,
  "settings": {
    "currency-code": "USD",
    "funding-type": "Actual Commitments"
  }
};
const searchApiJson = {
  "add_columns": ["Activity Id", "National Planning Objectives Level 1", "Donor Agency", "Actual Start Date", "Actual Completion Date", "Proposed Start Date", "Proposed Completion Date", "Administrative Level 0", "Project Title"],
  "add_measures": ["Actual Commitments"],
  "rowTotals": true,
  "show_empty_rows": true,
  "name": "Activity search"
};
export const loadAmpFilters = (filterName, locale, api_root) => {
  const url = `${api_root + FILTERS_API}/${filterName}?language=${locale}`;
  return get(url);
};
export const searchActivities = ({
  filters,
  keyword,
  page,
  pageSize,
  currency,
  isDownload,
  locale,
  api_root,
  sorting
}) => {
  const url = api_root + SEARCH_API + '?language=' + locale;
  const newSearchApiJson = {
    ...searchApiJson
  };
  if (!isDownload) {
    newSearchApiJson.page = page;
    newSearchApiJson.recordsPerPage = pageSize;
  } else {
    newSearchApiJson.page = 0;
    newSearchApiJson.recordsPerPage = -1;
  }
  newSearchApiJson.filters = filters;
  newSearchApiJson.settings = {
    'currency-code': currency
  };
  if (keyword) {
    newSearchApiJson.filters.keyword = keyword;
  }
  if (sorting) {
    newSearchApiJson.sorting = sorting;
  }
  return post(url, newSearchApiJson);
};
export const getData = (path, params, app, measure, dateFilter, locale, keyword, api_root) => {
  if (app === 'totalWidget') {
    let url;
    if (measure === 'Total Activities') {
      url = api_root + COUNT_API;
    } else {
      url = api_root + TOTAL_API;
      filters_data['settings']['funding-type'] = measure;
      filters_data['settings']['currency-code'] = params[0];
    }
    url = `${url}?language=${locale}`;
    return post(url, filters_data);
  } else if (app === 'top') {
    let url = api_root + TOP_API + measure.toLowerCase() + '?limit=5';
    url = `${url}&language=${locale}`;
    return post(url, filters_data);
  } else {
    return searchActivities({}, keyword, 1, 10, 'USD', false, locale);
  }
};
export const fetchIndicators = ({
  country,
  objectives,
  program,
  objective,
  subPillar,
  filterByCountry,
  filterByProgram,
  filterBySubPillar,
  api_root,
  filterByDonor,
  donor
}) => {
  const url = `${api_root}${INDICATORS_API}`;
  const settings = {
    "calendar-id": "4",
    currencyCode: "USD",
    yearCount: 10
  };
  const filters = {};
  if (filterByCountry) {
    filters["administrative-level-0"] = country ? [parseInt(country)] : [];
  }
  if (filterByProgram) {
    const programArray = [];
    programArray.push(parseInt(program));
    filters["national-planning-objectives-level-1"] = programArray ?? [];
  }
  if (filterByDonor) {
    filters["donor-agency"] = donor ? [parseInt(donor)] : [];
  }
  if (filterBySubPillar) {
    filters["national-planning-objectives-level-2"] = [parseInt(subPillar)];
  }
  const requestBody = {
    settings,
    filters
  };
  return post(url, requestBody);
};
export const getCoreIndicatorData = (app, params) => {
  const URL = `/api/${app}/core-indicator-progress`;
  const buildUrl = `${VIZ_API_ROOT ? VIZ_API_ROOT : ''}${URL}${params ? '?' + queryParams(params) : ''}`;
  return get(buildUrl);
};

//wocat
export const loadWocatSahelCountries = () => {
  const url = `${VIZ_API_ROOT ? VIZ_API_ROOT : '' + WOCAT_SAHEL_COUNTRIES_API}`;
  debugger;
  return axios.get(url);
};
export const loadWocatProjects = () => {
  const url = `${VIZ_API_ROOT ? VIZ_API_ROOT : '' + WOCAT_PROJECTS_API}`;
  return axios.get(url);
};
export const loadWocatInstitutions = () => {
  const url = `${VIZ_API_ROOT ? VIZ_API_ROOT : '' + WOCAT_INSTITUTIONS_API}`;
  return axios.get(url);
};
export const loadWocatLanguages = () => {
  const url = `${VIZ_API_ROOT ? VIZ_API_ROOT : '' + WOCAT_LANGUAGES_API}`;
  return axios.get(url);
};
export const loadWocatTypes = () => {
  const url = `${VIZ_API_ROOT ? VIZ_API_ROOT : '' + WOCAT_TYPES_API}`;
  return axios.get(url);
};
export const loadWocatData = async (size, page, filters) => {
  const params = new URLSearchParams({
    size,
    page
  });
  Object.entries(filters).forEach(([key, value]) => {
    if (value) {
      params.append(key, value);
    }
  });
  return axios.get(`${VIZ_API_ROOT ? VIZ_API_ROOT : ''}${WOCAT_SEARCH_API}?${params.toString()}`);
};