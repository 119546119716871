export const extractPillars = (programs = []) => {
  programs = programs.filter(program => program.id > 0);
  const pillars = [];
  programs.forEach(program => {
    pillars.push(...program.children);
  });
  return pillars;
};
export const extractObjectivesIds = (programs = [], pillar) => {
  if (programs.length === 0) {
    return [];
  }
  const pillars = extractPillars(programs);
  const pillarData = pillars.find(item => item.id === parseInt(pillar));
  if (!pillarData) {
    return [];
  }
  return pillarData.children.map(child => child.id);
};