export const buildTopsData = data => {
  const result = {};
  result.values = data.values.slice(0, 5).map((v, index) => ({
    id: v.id.toString(),
    formattedAmount: v.amount,
    name: v.name,
    value: v.amount,
    index: index
  }));
  const others = data.total - data.values.reduce((acc, cur) => acc + cur.amount, 0);
  if (others > 0) {
    const o = {
      id: '-9999',
      name: 'Others',
      value: others,
      formattedAmount: 99999,
      index: 5
    };
    result.values.push(o);
  }
  result.currency = data.currency;
  result.total = data.total;
  return result;
};
export const getColor = (item, colors, filter) => {
  if (filter && filter.includes(item.id)) {
    return '#EEE';
  }
  let color = ampColors; // colors.scheme === 'amp_dashboards' ? ampColors : colorSchemes[colors.scheme];
  const index = item.index !== undefined ? item.index : item.data !== undefined ? item.data.index : null;
  return color[index];
};
const ampColors = ['rgba(0, 106, 78, 1)', 'rgba(243, 175, 0, 1)', 'rgba(255, 206, 0, 1)', 'rgba(54, 60, 74, 1)', 'rgba(166, 133, 196, 1)', 'rgba(153, 153, 153, 1)', 'rgba(217, 91, 95, 1)', 'rgba(253, 170, 170, 1)', 'rgba(206, 189, 218, 1)'];