import Immutable from 'immutable';
import { LOAD_DATA, LOAD_DATA_DONE, LOAD_DATA_ERROR } from "./data";
const defaultState = {
  data: {}
};
const initialState = Immutable.Map(defaultState);
export const AMP_REDUCER_STORE = 'AmpReducer';
export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_DATA:
      {
        const {
          store
        } = action;
        return state.deleteIn([store, 'error']).setIn([store, 'loading'], true);
      }
    case LOAD_DATA_ERROR:
      {
        const {
          error,
          store
        } = action;
        return state.setIn([store, 'loading'], false).setIn([store, 'error'], error);
      }
    case LOAD_DATA_DONE:
      {
        const {
          data,
          store
        } = action;
        return state.setIn([store, 'loading'], false).deleteIn([store, 'error']).setIn([store, 'data'], data);
      }
    default:
      return state;
  }
};